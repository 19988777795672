import Swiper from 'swiper/bundle';

var feature_sliders = document.querySelectorAll('.feature-slider');
var feature_sliders_alt = document.querySelectorAll('.feature-slider-alt');

feature_sliders.forEach((obj, index) => {
    new Swiper(obj.querySelector('.feature-slider-container'), {
        direction: 'horizontal',
        loop: true,
        effect: 'fade',
        autoplay: {
            delay: 5000,
        },
        watchOverflow: true,
        allowTouchMove: false,
        noSwipingSelector: 'a',
        a11y: false,
    
        // If we need pagination
        pagination: {
            el: obj.querySelector('.feature-slider-controls'),
            clickable: true,
        },
    
        on: {
            slideChangeTransitionEnd: () => {
                if ($('.swiper-slide-active .feature-video').length && $(window).width() < 1024) {
                    $('.feature-image-container').toggleClass('video-slider-height');
                } else {
                    $('.feature-image-container').removeClass('video-slider-height');
                }
            },
            init: () => {
                if ($('.swiper-slide-active .feature-video').length && $(window).width() < 1024) {
                    $('.feature-image-container').addClass('video-slider-height');
                } else {
                    $('.feature-image-container').removeClass('video-slider-height');
                }
            }
        }
    });
})


feature_sliders_alt.forEach((obj, index) => {
    new Swiper(obj.querySelector('.feature-slider-container'), {
        direction: 'horizontal',
        loop: true,
        effect: 'fade',
        autoplay: {
            delay: 5000,
        },
        watchOverflow: true,
        noSwipingSelector: 'a',
        a11y: false,
        allowTouchMove: false,
    
        // If we need pagination
        pagination: {
            el: obj.querySelector('.feature-slider-controls'),
            clickable: true,
        },
    
        on: {
            slideChangeTransitionEnd: () => {
                if ($('.swiper-slide-active .feature-video').length && $(window).width() < 1024) {
                    $('.feature-image-container').toggleClass('video-slider-height');
                } else {
                    $('.feature-image-container').removeClass('video-slider-height');
                }
            },
            init: () => {
                if ($('.swiper-slide-active .feature-video').length && $(window).width() < 1024) {
                    $('.feature-image-container').addClass('video-slider-height');
                } else {
                    $('.feature-image-container').removeClass('video-slider-height');
                }
            }
        }
    });
})
// Featured Wines Slider
const content_slider = new Swiper('.swiper-container', {
    direction: 'horizontal',
    autoHeight: false,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 25
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 25
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 25
      },
      1440: {
        slidesPerView: 4,
        spaceBetween: 25
      }
    },
    autoplay: {
      delay: 3000,
    },
    centeredSlides: false,
    loop: jQuery(".swiper-container .swiper-slide").length != 1,
    watchOverflow: true, 
    navigation: {
      prevEl: '.swiper-previous',
      nextEl: '.swiper-next',
    },
});


// 3 Boxes Slider
var events_content_slider = new Swiper('.swiper-container-boxes', {
    direction: 'horizontal',
    autoHeight: false,
    loop: true,
    watchOverflow: true,
    effect: 'slide',
    // slidesPerView: 5,
    // spaceBetween: 10,
    breakpoints: {
      '@0.00': {
        slidesPerView: 1.2,
        spaceBetween: 25,
        slidesOffsetBefore: -16
      },
      '@0.50': {
        slidesPerView: 1.2,
        spaceBetween: 25,
        slidesOffsetBefore: -16
      },
      '@0.70': {
        slidesPerView: 1.2,
        spaceBetween: 25,
        slidesOffsetBefore: -16
      },
      '@0.80': {
        slidesPerView: 1.2,
        spaceBetween: 25,
        slidesOffsetBefore: -16
      },
      '@0.85': {
        slidesPerView: 3,
        spaceBetween: 25
      },
      '@0.90': {
        slidesPerView: 3,
        spaceBetween: 25
      },
      '@1.00': {
        slidesPerView: 3,
        spaceBetween: 25
      },
      '@1.25': {
        slidesPerView: 3,
        spaceBetween: 25
      },
      '@1.50': {
        slidesPerView: 3,
        spaceBetween: 25
      }
    },
    centeredSlides: true,
    initialSlide: 1,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-previous',
    },
});

// Quiz Slider
var feature_sliderQuiz = new Swiper('.quiz-slider-container', {
    direction: 'horizontal',
    loop: true,
    effect: 'fade',
    autoplay: false,
    observer: true,
    noSwiping: true,
    noSwipingClass: 'swiper-slide',
    allowTouchMove: false,
    observeParents: true,
    navigation: {
        nextEl: '.quiz-slider-container .btn-black',
    },
});
// Show Quiz
$(".start_quiz").on('click', function() {
    $(this).hide();
    $(".quiz-slider").fadeIn(1000);
});

// Basic Slider
var basic_slider = new Swiper('.basic-slider-container', {
    direction: 'horizontal',
    loop: true,
    effect: 'fade',
    autoplay: {
        delay: 5000,
    },
    pagination: {
        el: '.basic-slider-controls',
        clickable: true,
    },
});